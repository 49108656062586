.content-page {
  width:95%;
  max-width: 1224px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 1024px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
  h2.sectionTitle {
    margin-top: 25px;
    font-size: 2rem;
    text-align: left;
    padding-bottom: 0.5em;
  }

  h2.noBottomPadding {
    padding-bottom: 0;
  }

  p.sectionSubtitle {
    text-align: left;
  }

  .cart-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    max-width: 1224px;
    margin: 0 auto;
  }

  .cart-card {
    position: relative;
    width: 23%;
    margin: 0 1% 2%;
    .card-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      justify-content: flex-end;
      min-width: 0;
      word-wrap: break-word;
      background-clip: border-box;
      border: 1px solid rgba(0,0,0,.125);
      border-radius: .25rem;
      padding: .15em;
      height: 100%;
      background-color: #ffffff;

      img {
        width: 100px;
        margin: 1em auto 0.5em;
      }

      .card-title {
        font-weight: 600;
        padding: 0 0.5em;
        margin: 1em 0 1.5em;
        text-transform: uppercase;
      }

      .cart-btn {
        width: 80%;
        margin: 0 auto 1rem;
        border-radius: 7px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .content-page {
    h2.sectionTitle {
      font-size: 1.75rem;
    }
    .cart-card {
      width: 31%;
      margin: 0 1% 2%;
    }
  }
  
}

@media screen and (max-width:767px) {
  .content-page {
    .cart-card {
      width: 48%;
    }
  }
}

@media screen and (max-width:500px) {
  .content-page {
    .cart-card {
      width:100%;
      margin: 0 auto 3%;
      .card-item {
        margin: 0 10px;
      }
    }
  }
}
