// .slide img {
//     max-width: 100%; /* Limit the image width to fit within the slide */
//     max-height: 100%; /* Limit the image height to fit within the slide */
//     object-fit: contain; /* Maintain the aspect ratio of the image */
// }
.carousel-container {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 1024px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.carousel.carousel-slider .control-arrow {
    // background: rgba(0,0,0,0.2);
    height: 85%;
}




.product--image {
    width: 100%;
    // height: 16em;
    object-fit: contain;
    margin-bottom: 1em;
  }
  
  .carousel-container .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 346px;
    margin: auto;
    text-align: center;
    font-family: arial;
  }
  
  .price {
    color: grey;
    font-size: 22px;
  }
  
  .carousel-container .card button {
    border: none;
    outline: 0;
    padding: 12px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
  }
  
  .carousel-container .card button:hover {
    opacity: 0.7;
  }



.cta-container {
  align-self: stretch;
  padding: 0; /* Set padding to 0 to ensure button fills container */
  background: #0006B1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.clip-coupon-btn {
  color: white;
  font-weight: 700;
  font-size: 18px;
  background: none;
  border: none;
  padding: 24px; /* Move padding to the button itself */
  cursor: pointer;
  outline: none;
  width: 100%; /* Stretch button width to fill the container */
  height: 100%; /* Stretch button height to fill the container */
}

.clip-coupon-btn:focus {
  outline: none;
}