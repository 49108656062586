.instagram-section {
    margin-bottom: 25px;
    grid-gap: 24px;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(386px, auto));
    grid-template-rows: auto;
    max-width: 1200px;
    margin: 0 auto;
    @media (min-width: 1544px) {
      grid-template-columns: repeat(3, 386px);
   }

    @media (max-width: 772px) {
      grid-template-columns: repeat(auto-fill, minmax(320px, auto));
    } 
    .instagram-post {
      position: relative;
      background: #2065c3;
      overflow: hidden;
      border-radius: 0;
      border: 0;
      width: 100%;
      height: auto;
      aspect-ratio: 1;
      max-width: 386px;
      max-height: 386px;
      margin: 0 auto;
      @media (max-width: 1024px) {
        max-width: 320px;
        max-height: 320px;
      } 
      a {
        text-decoration: none;
  
        .instagram-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
  
      .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        display: flex;
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center content horizontally */
        padding: 5px;
        opacity: 0;
        transition: opacity 0.3s ease;
  
        .instagram-text,
        .social-icons {
          opacity: 1;
          display: block; /* Stack elements on top of each other */
          text-align: center; /* Center text and icons horizontally */
        }
  
        .instagram-text {
          color: #000;
          padding: 5px;
        }
  
        .social-icons {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px;
  
          .btn-social {
            display: inline-flex;
            justify-content: center;
            align-items: center;

            padding-left: 15px;
            padding-right: 15px;

            border: 1px solid #ccc;
            border-radius: 6px;
            text-decoration: none;
            transition: color 0.3s ease;
            // background-color: #fff; /* Add background color */
          }
  
          .fa {
            font-size: 18px;
            color: #585858;
          }
  
          .btn-social:hover .fa {
            color: #444444;
          }
        }
      }
  
      &:hover {
        .footer {
          opacity: 10;
        }
      }
    }
  }
  
