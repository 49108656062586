@font-face {
  font-family: 'MergeProLight';
  src: url('/fonts/MergeProLightW00-Bold.ttf') format('truetype');
}
@import url("https://use.typekit.net/wfq7oyh.css");
.recipe-content-page.default {
  width: 80%; /* Set the width to 80% */
  margin: 25px auto; /* Center the component using auto margins */

  .recipeArea {

    .recipeBlock {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      -webkit-justify-content: center;
      justify-content: center;
      margin: 0 auto;
      .recipe-item {
        box-shadow: 0px 3px 10px 0 rgba(110,118,152,0.25);
        border-radius: 20px;
        width: 25%;
        margin: 0 1% 2%;
        .imageContent {
          position: relative;
          background-size: cover;
          background-position: center;
          // height: 150px;
          overflow: hidden;
          border-radius: 20px 20px 0 0;
          a {
            position: absolute;
            right: 5%;
            bottom: 5%;
            cursor: pointer;
          }
          .fa {
            border: 0.5px solid rgba(110,118,152,0.25);
            box-shadow: 0px 3px 10px 0 rgba(110,118,152,0.25);
            height: 40px;
            border-radius:50%;
            width: 40px;
            padding: 10px;
            text-align: center;
          }
          img {
            border-radius: 20px 20px 0 0;
            // width: auto;
          }
        }
        .textContent {
          padding: 20px 16px 16px 16px;
          .button-items {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 12px;
            .recipe-btn {
              border-radius: 6px;
              font-weight: 700;
              font-size: 14px;
              line-height: 1.2;
              padding: 10px;
              text-decoration: none;
            }
            .cart-btn {
              font-weight: 700;
              font-size: 14px;
              line-height: 1;
            }
          }
          .text-block {
            text-align: center;
            h4 {
              font-size: 18px;
              font-weight: 600;
              margin-bottom: 8px;
            }
            p {
              font-size: 14px;
              margin: 0;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;  
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width:1100px) {
    .recipeArea .recipeBlock {
      .recipe-item {
        width: 30%;
        margin: 0 1% 2%;
      }
    }
  }
  @media screen and (max-width:960px) {
    .recipeArea .recipeBlock {
      .recipe-item {
        width: 40%;
        margin: 0 1% 2%;
      }
    }
  }
  @media screen and (max-width:767px) {
    width: 95%;
    margin: 0 auto;
    .recipeArea .recipeBlock {
      .recipe-item {
        width: 48%;
      }
    }
  }
  @media screen and (max-width:500px) {
    .recipeArea .recipeBlock {
      .recipe-item {
        width:100%;
        margin: 0 auto 3%;
        .imageContent {
          img {
            // width: auto;
            height: 100%;
            max-height: none;
          }
        }
      }
      
    }
  }
}
// RecipeDetail
.recipeDetail.default {
  // padding: 2em 0 3em;
  .header {
    position: relative;
    .headerImage {
      height: 100%;
      min-height: 600px;
      background-size: cover;
      background-position: center;
      border-radius: 20px;
    }

    .backButton {
      cursor: pointer;
    }
    
    .fa {
      border: 0.5px solid rgba(110,118,152,0.25);
      box-shadow: 0px 3px 10px 0 rgba(110,118,152,0.25);
      height: 40px;
      border-radius:50%;
      width: 40px;
      padding: 10px;
      text-align: center;
      position: absolute;
      left: 2.5%;
      top: 5%;
    }
    img {
      display: none;
      width: 100%;
      border-radius: 20px;
      z-index: 0;
    }
  } 
  .subheader {
    box-shadow: 0px 3px 10px 0 rgba(110,118,152,0.25);
    border-radius: 20px;
    width: 95%;
    max-width: 1224px;
    position: relative;
    margin: -80px auto 0;
    z-index: 1;
    .subheaderContent{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 3em 5em;
      width: 100%;
      margin: 0 auto;
      .social {
        width: 75%;
        display: flex;
        flex-direction: column;
        h2.title {
          font-family: nunito-sans, sans-serif;
          font-weight: 700;
          font-size: 48px;
          line-height: 1;
          text-align: left;
          margin-bottom: 24px;

        }
        h3 {
          font-family: nunito-sans, sans-serif;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          text-align: left;
        }
        .socialIcons {
          margin-top: 1.5em;
          text-align: left;
          display: flex;
          img {
            padding: 0 0.25em;
            cursor: pointer;
          }
          .btn-social {
            margin-right: 8px;
            cursor: pointer;
          }
          .fa {
            border: 0.5px solid rgba(110,118,152,0.25);
            box-shadow: 0px 3px 10px 0 rgba(110,118,152,0.25);
            height: 40px;
            border-radius:50%;
            width: 40px;
            padding: 10px;
            text-align: center;
          }
        }
      }
      .times {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        div {
          margin: 0 auto;
          padding: 0.75em 1em;
          img {
            width: 100%;
            max-width: 150px;
          }
          p {
            text-align: center;
            font-family: nunito-sans, sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            margin: 0;
            strong {
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
      }
    }
    img.border {
      width: 98%;
      margin: 0 auto
    }
  }
  .detail {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 95%;
    margin: 0 auto;
    padding: 3em 0;
    max-width: 1224px;
    font-family: nunito-sans, sans-serif;

    .ingredients,
    .directions {
      h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 16px;
      }
    }

    .ingredients {
      width: 30%;
      text-align: left;
      .a2c-block {
        border-radius: 20px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
        max-width: 275px;
        p {
          font-size: 18px;
          line-height: 22px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 8px;
        }
        .cart-btn {
          border-radius: 5px;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          padding: 12px 16px;
          width: 100%;
          max-width: 400px;
         
          &:hover {
            a {
              text-decoration: none !important;
            }
          }
        }
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
      }
      h4 {
        margin: 1.5em 0 0.75em;
        font-size: 1.125em;
      }
    }
    
    .directions {
      width: 60%;
      text-align: left;
      padding-left: 3.5%;
      .number {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
      }
    }
  }

  @media screen and (max-width: 767px) {
  padding: 0 0 3em;
    .header {
      .headerImage {
        min-height: 300px;
        border-radius: 0;
      }
    }
    .subheader {
      margin: -25px auto 0;
      .subheaderContent {
        padding: 2em;
        flex-direction: column;
        .social {
          width: 100%;
        }
        .times {
          flex-direction: row;
          width: 100%;
          margin-top: 16px;
        }
      }
    }
    .detail {
      flex-direction: column;
      .ingredients,
      .directions {
        width: 100%;
        padding: 0;
        .a2c-block {
          width: 100%;
          max-width: none;
        }
      }
    }
  }
  @media screen and (max-width:400px) {
    .subheader .subheaderContent .social h2.title {
      font-size: 38px;
    }
  }
  @media print {
    .header {
      .headerImage {
        min-height: 0;
      }
      .backButton {
        display: none;
      }
      img.print-only {
        display: block;
      }
    }
    .subheader {
      border: 1px solid #e3e3e3;
      .subheaderContent {
        .social .socialIcons {
          display: none;
        }
      }
    }
    .detail .ingredients .a2c-block {
      display: none;
    }
  }
}

.recipe-container {
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 1024px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.recipe-container h2 {
  max-width: 80%;
  text-align: left;
}