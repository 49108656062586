.information-banner-container {
  max-width: 90%;
  display: flex;
  margin: 0 125px;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 1024px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0px;
  }
}

.information-banner-left {
  flex: 1;
  width: 40%;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
}

.information-banner-right {
  max-width: 50%;
  width: 50%;

  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
}

.information-banner-heading {
  font-style: normal;
  text-align: left;
  font-family: 'Bebas Neue';
  padding-top: 50px;
  font-size: 49px;
  line-height: 100%;
  margin: 50px;
  max-width: 90%;

  @media (max-width: 768px) {
    text-align: center;
  }
}

#view-qualifying-products-button button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;

  order: 1;
  flex-grow: 0;
  margin: 50px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  text-align: center;
  text-transform: uppercase;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    display: initial;
  }
}

.qp-custom-button {
  border: 1px solid #8757FF;
}

.icon-container {
  margin-right: 15px;
}

.information-list-bullet-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  flex-direction: row;

}

@media (max-width: 768px) {
.icon-container{
  margin-bottom: 5px;
  }
}

.information-list-bullet-header {
  padding: 0px 5px 5px 0px;
  font-style: normal;
  font-size: 39px;
  line-height: 100%;
  text-align: left;
  font-family: 'Bebas Neue';
  overflow-wrap: break-word !important; /* Allow header to break to the next line if there is overflow */
}

.information-list-bullet-description {
  display: flex;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  order: 2;
  text-align: left;
  overflow-wrap: break-word; /* Allow text to break to the next line if there is overflow */
  p {

    max-width: 100%;
    width: 100%;
  }
  @media (max-width: 768px) {
    p {
      max-width: 100%;
      width: 100%;
    }
  }
}

.divider {
  display: flex;
  height: 5px;
  margin: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
}

.divider-section {
  flex: 1;
}

.information-list-bullet-column {
  padding: 25px;
  padding-right: 0px;
}
