// Desktop styles
h1.responsive-h1, .responsive-h1 { font-size: 136px; }
h2.responsive-h2, .responsive-h2 { font-size: 90px; }
h3.responsive-h3, .responsive-h3 { font-size: 60px; }
h4.responsive-h4, .responsive-h4 { font-size: 42px; }
p.responsive-p-lg, .responsive-p-lg { font-size: 28px; }
p.responsive-p-md, .responsive-p-md { font-size: 18px; }
p.responsive-p-sm, .responsive-p-sm { font-size: 16px; }

// Tablet styles
@media (max-width: 1199px) {
  h1.responsive-h1, .responsive-h1 { font-size: 80px; }
  h2.responsive-h2, .responsive-h2 { font-size: 64px; }
  h3.responsive-h3, .responsive-h3 { font-size: 46px; }
  h4.responsive-h4, .responsive-h4 { font-size: 32px; }
  p.responsive-p-lg, .responsive-p-lg { font-size: 24px; }
  p.responsive-p-md, .responsive-p-md { font-size: 16px; }
  p.responsive-p-sm, .responsive-p-sm { font-size: 14px; }
}

// Mobile styles
@media (max-width: 767px) {
  h1.responsive-h1, .responsive-h1 { font-size: 42px; }
  h2.responsive-h2, .responsive-h2 { font-size: 36px; }
  h3.responsive-h3, .responsive-h3 { font-size: 30px; }
  h4.responsive-h4, .responsive-h4 { font-size: 24px; }
  p.responsive-p-lg, .responsive-p-lg { font-size: 18px; }
  p.responsive-p-md, .responsive-p-md { font-size: 14px; }
  p.responsive-p-sm, .responsive-p-sm { font-size: 12px; }
}

.free-text-block {

  #spoonable-header{
    font-family: 'Bebas-Neue';
  }
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 80px;
    padding-bottom: 80px;
    @media (max-width: 1024px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    
    @media (max-width: 768px) {
      max-width: 90%;
    }
  }
  
  .free-text-title {
    margin-bottom: 15px;
  }
  
  .columns-container {
    display: flex;
    width: 100%;
    
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  
  .column {
    flex: 1;
    margin: 0 10px;
    width: 30%;
    margin-left: 25px;
    margin-right: 25px;
    
    @media (max-width: 768px) {
      margin: 10px 0;
      width: 100%;
    }
    
    p {
      text-align: left;
    }
  }
  